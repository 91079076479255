import { SHADOWS } from '@/UI/globalTheme';

export const theme = {
	modalOverlay: {
		backgroundColor: 'rgba(16, 24, 40, 0.7)',
		position: 'fixed' as const,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 9999,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	modalContent: {
		backgroundColor: 'white',
		padding: '24px',
		borderRadius: '12px',
		boxShadow: SHADOWS.xl,
		maxWidth: '500px',
		width: '90%',
		maxHeight: '90vh',
		overflowY: 'auto' as const,
		position: 'relative' as const
	},
	modalClose: {
		position: 'absolute' as const,
		top: '10px',
		right: '10px',
		background: 'none',
		border: 'none',
		fontSize: '24px',
		cursor: 'pointer'
	}
};
