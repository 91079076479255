import ReactMarkdown from 'react-markdown';

interface MarkdownRendererProps {
	content?: string;
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content }) => {
	return (
		<div className="markdown-content">
			<ReactMarkdown>{content || ''}</ReactMarkdown>
			<style>{`
        .markdown-content {
          text-align: justify;
          font-family: 'Outfit', sans-serif;
        }
        
        .markdown-content p {
          margin-top: 1.5rem;
          margin-bottom: 1rem;
          color: rgb(31 41 55);
        }
        
        .markdown-content a {
          color: #6c63ff;
          text-decoration: underline;
        }
        
        .markdown-content h1 {
          margin-top: 1rem;
          margin-bottom: 1.5rem;
          font-size: 1.25rem;
          line-height: 1.75rem;
          font-weight: 500;
          text-align: center;
        }
        
        .markdown-content h2 {
          margin-top: 0.75rem;
          margin-bottom: 1.5rem;
          font-size: 1.125rem;
          line-height: 1.75rem;
          font-weight: 500;
        }
        
        .markdown-content h3 {
          margin-top: 0.5rem;
          margin-bottom: 1.5rem;
          font-size: 1.125rem;
          line-height: 1.75rem;
        }
        
        .markdown-content ul,
        .markdown-content ol {
          margin-top: 1.5rem;
        }
        
        .markdown-content ul {
          list-style-type: disc;
        }
        
        .markdown-content ol {
          list-style-type: decimal;
        }
        
        .markdown-content li {
          margin-top: 1.5rem;
          list-style-position: inside;
        }
        
        @media (min-width: 768px) {
          .markdown-content h1 {
            margin-top: 1.5rem;
            margin-bottom: 2rem;
            font-size: 1.5rem;
            line-height: 2rem;
          }
          
          .markdown-content h2 {
            margin-top: 1.25rem;
            margin-bottom: 2rem;
            font-size: 1.25rem;
            line-height: 1.75rem;
          }
          
          .markdown-content h3 {
            margin-top: 1rem;
            margin-bottom: 2rem;
            font-size: 1.25rem;
            line-height: 1.75rem;
          }
          
          .markdown-content ul,
          .markdown-content ol,
          .markdown-content li {
            margin-top: 2.5rem;
          }
        }
      `}</style>
		</div>
	);
};

export default MarkdownRenderer;
