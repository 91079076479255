import React from 'react';
import MarkdownRenderer from '@/UI/Components/MarkdownRenderer/MarkdownRenderer';
import Terms from '@/lib/markdown/terms.md?raw';

const TermsConditions: React.FC = () => {
	return (
		<div className="flex justify-center items-center p-6">
			<MarkdownRenderer content={Terms} />
		</div>
	);
};

export default TermsConditions;
