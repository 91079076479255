import React from 'react';
import { Typography } from '@/UI/Components/Typography/Typography';
import { ChevronRight } from 'react-feather';
import DownloadAppButton from '@/UI/Components/DownloadAppButton/DownloadAppButton';

const RoadMapSection: React.FC = () => {
	const RoadMapSteps = [
		{
			step: 1,
			title: 'Registro y Creación de Cuenta',
			description:
				'Crea tu cuenta en Fincluyente de forma fácil y segura para acceder a nuestros servicios de crédito.',
			cardColor: 'bg-[#8CADC7]'
		},
		{
			step: 2,
			title: 'Verificación de Información',
			description: 'Selecciona el monto y plazo que necesitas y envía tu solicitud.',
			cardColor: 'bg-[#6F99B9]'
		},
		{
			step: 3,
			title: 'Solicitud de Crédito',
			description: 'Recibe tu crédito en tu cuenta bancaria en menos de 24 horas.',
			cardColor: 'bg-[#4C80A7]'
		},
		{
			step: 4,
			title: 'Aprobación y Firma:',
			description: 'Realiza tus pagos a tiempo y aumenta tu límite de crédito.',
			cardColor: 'bg-[#1E6091]'
		},
		{
			step: 5,
			title: 'Usa y administra tu Crédito',
			description:
				'Una vez aprobado, el monto se depositará en tu cuenta. Administra tus pagos de manera sencilla desde la app y mantén un historial claro de tus transacciones.',
			cardColor: 'bg-[#194D74]'
		}
	];

	interface RoadMapCardProps {
		step: number;
		title?: string;
		description: string;
		cardColor: string;
	}

	const RoadMapCard: React.FC<RoadMapCardProps> = ({ step, title, description, cardColor }) => {
		return (
			<div>
				<div
					className={`flex flex-row gap-4 ${cardColor} rounded-md md:rounded-full items-center py-2 px-4`}
				>
					<div className="flex flex-col items-center ">
						<div className="flex rounded-full bg-white px-4 w-12 h-12 items-center justify-center">
							<Typography size={24} weight="bold" color="black">
								{step}
							</Typography>
						</div>
					</div>
					<div className="flex flex-col gap-2">
						<Typography size={18} weight="bold" color="white">
							{title}
						</Typography>
						<Typography size={16} color="white">
							{description}
						</Typography>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="flex flex-col gap-4 w-full p-6">
			<div className="flex flex-col gap-6 sm:flex-row sm:justify-center min-h-[calc(100vh-70px)] md:min-h-[calc(100vh-90px)] py-6">
				<div className="flex flex-col md:flex-row w-full md:w-4/5 items-center gap-6 justify-center">
					<div className="flex flex-col gap-8 px-6">
						<Typography size={17} weight="medium" color="black">
							El camino del emprendedor
						</Typography>
						<div className="flex flex-col gap-2">
							<Typography size={44} weight="bold" color="black">
								Accede a Crédito para Impulsar tu Negocio
							</Typography>
						</div>
						<div className="flex flex-col md:flex-row gap-3">
							<DownloadAppButton />
							<a
								className="py-2 px-8 rounded-lg flex flex-row gap-2"
								target="_blank"
								href={`https://wa.me/+525576972475?text=${encodeURIComponent('Hola Finclu!')}`}
							>
								<Typography size={14} weight="semibold" color="black">
									<u>Habla con Finclu</u>
								</Typography>
								<ChevronRight className="shrink" color="black" />
							</a>
						</div>
					</div>
					<div className="flex flex-col gap-4">
						{RoadMapSteps.map((step, index) => (
							<RoadMapCard
								key={index}
								step={step.step}
								title={step.title}
								description={step.description}
								cardColor={step.cardColor}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default RoadMapSection;
