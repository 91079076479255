import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface FeedbackForm {
	name: string;
	email: string;
	message: string;
}

interface SupportForm {
	subject: string;
	description: string;
	email: string;
}

interface ModalProps {
	showModal: boolean;
	modalMessage: string;
	onClose: () => void;
}

const SuccessModal: React.FC<ModalProps> = ({ showModal, modalMessage, onClose }) => {
	if (!showModal) return null;

	return (
		<div
			className="fixed inset-0 z-50 overflow-y-auto"
			aria-labelledby="modal-title"
			role="dialog"
			aria-modal="true"
		>
			<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				{/* Background overlay */}
				<div
					className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
					aria-hidden="true"
					onClick={onClose}
				/>

				{/* Modal panel */}
				<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
					<div className="sm:flex sm:items-start">
						<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
							<svg
								className="h-6 w-6 text-green-600"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M5 13l4 4L19 7"
								/>
							</svg>
						</div>
						<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
							<h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
								¡Mensaje Enviado!
							</h3>
							<div className="mt-2">
								<p className="text-sm text-gray-500">{modalMessage}</p>
							</div>
						</div>
					</div>
					<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
						<button
							type="button"
							className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
							onClick={onClose}
						>
							Aceptar
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const ContactPage: React.FC = () => {
	const [feedbackForm, setFeedbackForm] = useState<FeedbackForm>({
		name: '',
		email: '',
		message: ''
	});

	const [supportForm, setSupportForm] = useState<SupportForm>({
		subject: '',
		description: '',
		email: ''
	});

	const [showModal, setShowModal] = useState(false);
	const [modalMessage, setModalMessage] = useState('');

	const validateEmail = (email: string): boolean => {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(email);
	};

	const validateFeedbackForm = (): boolean => {
		if (!feedbackForm.name.trim()) {
			alert('Por favor, ingresa tu nombre');
			return false;
		}
		if (!validateEmail(feedbackForm.email)) {
			alert('Por favor, ingresa un email válido');
			return false;
		}
		if (!feedbackForm.message.trim()) {
			alert('Por favor, ingresa un mensaje');
			return false;
		}
		return true;
	};

	const validateSupportForm = (): boolean => {
		if (!validateEmail(supportForm.email)) {
			alert('Por favor, ingresa un email válido');
			return false;
		}
		if (!supportForm.subject.trim()) {
			alert('Por favor, ingresa un asunto');
			return false;
		}
		if (!supportForm.description.trim()) {
			alert('Por favor, ingresa una descripción');
			return false;
		}
		return true;
	};

	const showNotification = (message: string) => {
		console.log('showNotification:', message);
		setModalMessage(message);
		setShowModal(true);
	};

	const handleFeedbackSubmit = () => {
		console.log('Feedback submitted:', feedbackForm);
		if (!validateFeedbackForm()) return;

		showNotification(
			'¡Gracias por tu feedback! Lo revisaremos pronto y nos pondremos en contacto si es necesario.'
		);
		setFeedbackForm({
			name: '',
			email: '',
			message: ''
		});
	};

	const handleSupportSubmit = () => {
		if (!validateSupportForm()) return;
		console.log('Support request submitted:', supportForm);

		showNotification(
			'¡Mensaje de soporte enviado! Revisaremos tu solicitud pronto y te contactaremos a la brevedad.'
		);
		setSupportForm({
			subject: '',
			description: '',
			email: ''
		});
	};

	return (
		<div className="bg-slate-50">
			<SuccessModal
				showModal={showModal}
				modalMessage={modalMessage}
				onClose={() => setShowModal(false)}
			/>

			<div className="container mx-auto px-4 py-8 max-w-6xl">
				{/* Legal Information Section */}
				<div className="bg-blue-50 rounded-lg shadow-md p-6 mb-8">
					<h2 className="text-xl font-semibold mb-4 text-blue-900">Información Regulatoria</h2>
					<div className="prose prose-blue max-w-none">
						<p className="text-blue-800">
							<strong>Finclu está autorizado para otorgar préstamos en México.</strong> De acuerdo
							con el{' '}
							<strong>
								Artículo 87 B de la Ley General de Organizaciones y Actividades Auxiliares del
								Crédito
							</strong>
							, cualquier empresa o persona puede otorgar créditos, siempre y cuando se realicen con
							recursos propios o con financiamiento proveniente de una entidad financiera. Esto
							garantiza que Finclu cumple con las regulaciones locales y ofrece sus servicios de
							manera segura y legal para apoyar tus necesidades financieras.
						</p>

						<Link to={'/credit-operation'}>
							<p className="mt-3 text-blue-900 underline hover:text-blue-500">
								<strong>
									Conoce más sobre los fundamentos legales que soportan nuestra operacion
									crediticia.
								</strong>
							</p>
						</Link>

						<Link to={'/privacy'}>
							<p className="mt-3 text-blue-900 underline  hover:text-blue-500">
								<strong>Conoce más sobre nuestra politica de privacidad.</strong>
							</p>
						</Link>
					</div>
				</div>

				<div className="flex items-center space-x-6 mb-8">
					<h1 className="font-bold text-2xl md:text-3xl">Soporte y Contacto</h1>
					<div className="border-b border-gray-300 flex-grow" />
				</div>

				<div className="grid md:grid-cols-2 gap-8">
					{/* Contact Information */}
					<div className="bg-white rounded-lg shadow-md p-6">
						<h2 className="text-xl font-semibold mb-4">Contacto Directo</h2>
						<div className="space-y-4">
							<p className="text-gray-600">
								Para cualquier consulta, no dudes en contactarnos directamente:
							</p>
							<div className="flex items-center space-x-2 text-gray-600">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
									<path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
								</svg>
								<a href="mailto:soporte@fincluyente.com" className="text-blue-600 hover:underline">
									soporte@fincluyente.com
								</a>
							</div>
						</div>

						{/* Support Form */}
						<div className="mt-6 space-y-4">
							<h3 className="font-semibold mb-2">Solicitud de Soporte</h3>
							<div>
								<label htmlFor="support-email" className="block text-sm font-medium text-gray-700">
									Email
								</label>
								<input
									type="email"
									id="support-email"
									value={supportForm.email}
									onChange={(e) => setSupportForm({ ...supportForm, email: e.target.value })}
									className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
								/>
							</div>
							<div>
								<label htmlFor="subject" className="block text-sm font-medium text-gray-700">
									Asunto
								</label>
								<input
									type="text"
									id="subject"
									value={supportForm.subject}
									onChange={(e) => setSupportForm({ ...supportForm, subject: e.target.value })}
									className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
								/>
							</div>
							<div>
								<label htmlFor="description" className="block text-sm font-medium text-gray-700">
									Descripción
								</label>
								<textarea
									id="description"
									value={supportForm.description}
									onChange={(e) => setSupportForm({ ...supportForm, description: e.target.value })}
									rows={4}
									className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
								/>
							</div>
							<button
								type="button"
								onClick={handleSupportSubmit}
								className="w-full bg-[#1E6091] text-white py-2 px-4 rounded-full hover:bg-[#6F99B9] transition-colors"
							>
								Enviar Solicitud
							</button>
						</div>
					</div>

					{/* Feedback Form */}
					<div className="bg-white rounded-lg shadow-md p-6">
						<h2 className="text-xl font-semibold mb-4">Déjanos tu Feedback</h2>
						<div className="space-y-4">
							<div>
								<label htmlFor="name" className="block text-sm font-medium text-gray-700">
									Nombre
								</label>
								<input
									type="text"
									id="name"
									value={feedbackForm.name}
									onChange={(e) => setFeedbackForm({ ...feedbackForm, name: e.target.value })}
									className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
								/>
							</div>
							<div>
								<label htmlFor="feedback-email" className="block text-sm font-medium text-gray-700">
									Email
								</label>
								<input
									type="email"
									id="feedback-email"
									value={feedbackForm.email}
									onChange={(e) => setFeedbackForm({ ...feedbackForm, email: e.target.value })}
									className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
								/>
							</div>
							<div>
								<label htmlFor="message" className="block text-sm font-medium text-gray-700">
									Mensaje
								</label>
								<textarea
									id="message"
									value={feedbackForm.message}
									onChange={(e) => setFeedbackForm({ ...feedbackForm, message: e.target.value })}
									rows={4}
									className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
								/>
							</div>
							<button
								type="button"
								onClick={handleFeedbackSubmit}
								className="w-full bg-green-600 text-white py-2 px-4 rounded-full hover:bg-green-700 transition-colors"
							>
								Enviar Feedback
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactPage;
