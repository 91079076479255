import React from 'react';
import { Typography } from '@/UI/Components/Typography/Typography';
import { ChevronRight } from 'react-feather';
import DownloadAppButton from '@/UI/Components/DownloadAppButton/DownloadAppButton';

const IntroSection: React.FC = () => {
	return (
		<div className="bg-[url('/IntroSectionBackground.svg')] overflow-hidden bg-no-repeat bg-cover">
			<div className="bg-[#518AC8] md:bg-transparent flex flex-col gap-6 sm:flex-row sm:justify-center items-center min-h-[calc(100vh-70px)] md:min-h-[calc(100vh-90px)] py-6">
				<div className="flex flex-col md:flex-row w-4/5 gap-6 justify-center">
					<div className="flex flex-col gap-8 w-full md:w-1/2">
						<div className="mb-12">
							<div className="block md:hidden">
								<Typography size={44} weight="semibold" color="white">
									Finanzas Incluyentes para Todos
								</Typography>
							</div>
							<div className="hidden md:block">
								<Typography size={78} weight="semibold" color="white">
									Finanzas Incluyentes para Todos
								</Typography>
							</div>
						</div>
						<div className="bg-[#518AC8] md:bg-white rounded-lg p-0 md:p-4 mb-0 2xl:mb-12">
							<p className="text-white md:text-black text-lg">
								En Fincluyente, creemos en un futuro financiero accesible para todos. Somos una
								plataforma inclusiva que elimina las barreras tradicionales. Con nuestro enfoque en
								la inclusión, democratizamos el acceso al crédito y otros servicios financieros,
								asegurando que cada persona en México pueda tomar el control de su futuro financiero
								con confianza y seguridad.
							</p>
						</div>
						<div className="flex flex-col md:flex-row gap-3">
							<DownloadAppButton />
							<a
								className="py-2 px-8 rounded-lg flex flex-row gap-2"
								target="_blank"
								href={`https://wa.me/+525576972475?text=${encodeURIComponent('Hola Finclu!')}`}
							>
								<Typography size={14} weight="semibold" color="black">
									<u>Habla con Finclu</u>
								</Typography>
								<ChevronRight className="shrink" color="black" />
							</a>
						</div>
					</div>
					<div className="flex items-center bg-auto justify-center">
						<img src="/IntroSectionImage.svg" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default IntroSection;
