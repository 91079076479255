import React, { useState, useEffect, useRef } from 'react';
import { X } from 'react-feather';
import { theme } from './theme';

interface ModalProps {
	isOpen: boolean;
	closeable: boolean;
	onClose: () => void;
	children: React.ReactNode;
	maxWidth?: string | number;
	dataTestId?: string;
	showCloseButton?: boolean;
}

const Modal: React.FC<ModalProps> = ({
	isOpen,
	closeable = false,
	onClose,
	children,
	maxWidth,
	dataTestId,
	showCloseButton = true
}) => {
	const [isVisible, setIsVisible] = useState(isOpen);
	const modalContentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setIsVisible(isOpen);
	}, [isOpen]);

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (
				modalContentRef.current &&
				!modalContentRef.current.contains(event.target as Node) &&
				closeable
			) {
				onClose();
			}
		};

		const handleEscapeKey = (event: KeyboardEvent) => {
			if (event.key === 'Escape' && closeable) {
				onClose();
			}
		};

		if (isVisible) {
			document.addEventListener('mousedown', handleOutsideClick);
			document.addEventListener('keydown', handleEscapeKey);
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
			document.addEventListener('keydown', handleEscapeKey);
		};
	}, [isVisible, onClose]);

	if (!isVisible) {
		return null;
	}

	return (
		<div style={theme.modalOverlay} data-testid={dataTestId || 'overlay'}>
			<div
				style={{
					...theme.modalContent,
					maxWidth: maxWidth || theme.modalContent.maxWidth
				}}
				ref={modalContentRef}
			>
				{closeable && (
					<button
						style={{
							...theme.modalClose,
							visibility: showCloseButton ? 'visible' : 'hidden'
						}}
						onClick={onClose}
						aria-label="close-button"
					>
						<X />
					</button>
				)}
				{children}
			</div>
		</div>
	);
};

export default Modal;
