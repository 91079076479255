import React from 'react';
import IntroSection from './IntroSection';
import CreditSection from './CreditSection';
import RoadMapSection from './RoadMapSection';
import InclusiveSection from './InclusiveSection';

const LandingLayout: React.FC = () => {
	return (
		<>
			<IntroSection />
			<InclusiveSection />
			<CreditSection />
			<RoadMapSection />
		</>
	);
};

export default LandingLayout;
