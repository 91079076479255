import React from 'react';
import { Typography } from '@/UI/Components/Typography/Typography';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
	return (
		<div
			className="w-full h-auto  md:h-[calc(25vh)] bg-cover justify-center items-center flex flex-col"
			style={{
				background: `linear-gradient(to bottom, #1E6091, #518AC8)`
			}}
		>
			<div className="flex flex-col md:flex-row w-full md:w-4/5 p-6 gap-4 md:gap-0">
				<div className="grow">
					<div className="flex flex-col gap-6">
						<Typography color="white" size={18}>
							Finanzas Incluyentes para Todos.
						</Typography>
						<div className="flex flex-row gap-8">
							<div className="flex flex-col gap-2">
								<Link to={'/terms-conditions'} className="cursor-pointer">
									<Typography color="white" size={14}>
										Términos y Condiciones
									</Typography>
								</Link>
								<Link to={'privacy'} className="cursor-pointer">
									<Typography color="white" size={14}>
										Aviso de Privacidad
									</Typography>
								</Link>
							</div>
							<div>
								<Link to={'credit-operation'} className="cursor-pointer">
									<Typography color="white" size={14}>
										Operación Crediticia
									</Typography>
								</Link>
							</div>
						</div>
						<Typography color="white" size={12}>
							Fincluyente @2024
						</Typography>
					</div>
				</div>
				<div className="flex flex-col md:flex-row gap-8">
					<div className="flex flex-col gap-3">
						<Typography color="white" size={12} weight="medium">
							SOPORTE
						</Typography>
						<Link to="/support" className="cursor-pointer">
							<Typography color="white">Solicitud de soporte</Typography>
						</Link>
						<a href="mailto:soporte@fincluyente.com">
							<Typography color="white">soporte@fincluyente.com</Typography>
						</a>
					</div>
					<div className="flex flex-col gap-3">
						<Typography color="white" size={12} weight="medium">
							REDES SOCIALES
						</Typography>
						<div className="flex gap-2 items-center">
							<a href="https://www.instagram.com/finclu_mx?igsh=ZDd6bXZpNTF2ZmFp" about="_blank">
								<img src="instagram-logo.svg" />
							</a>
							<a href="https://www.facebook.com/finclumx?mibextid=LQQJ4d" about="_blank">
								<img src="/facebook-logo.svg" />
							</a>
							<a href="https://www.tiktok.com/@fincluemprende?_t=8r7xUikYYXm&_r=1" about="_blank">
								<img src="/tiktok-logo.webp" width={29} />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
