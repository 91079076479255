// import { ChevronLeft } from 'react-feather';
import { useNavigate, Link } from 'react-router-dom';
import { Typography } from '@/UI/Components/Typography/Typography';
import { ArrowLeft, Home } from 'react-feather';

export default function NotFound() {
	const navigate = useNavigate();
	const handleGoBack = () => {
		return navigate(-1);
	};

	return (
		<div className="w-full h-screen flex flex-col justify-center items-center bg-[#F1F5F8]">
			<div className="flex flex-col items-center w-full md:max-w-[50%] text-center grow">
				<div className="flex-1 flex flex-col justify-center items-center gap-6">
					<div className="flex flex-col gap-1">
						<Typography size={28} color="brand">
							Error 404
						</Typography>
						<Typography size={54} weight="semibold">
							Esta página no existe
						</Typography>
					</div>
					<div>
						<Typography size={32} color="subtitle">
							Lo sentimos, la página que estás buscando no existe.
						</Typography>
					</div>
					<div className="flex flex-col md:flex-row gap-4 justify-around items-center mt-8">
						<button
							className="hover:bg-slate-100 transition-colors py-2 px-6 rounded-full flex flex-row gap-3 items-center"
							onClick={handleGoBack}
						>
							<ArrowLeft className="shrink" color="#1E6091" />
							<span className="grow">
								<Typography size={28} weight="medium" color="#1E6091">
									Regresar
								</Typography>
							</span>
						</button>
						<Link to={`/`}>
							<button className="hover:bg-slate-100 transition-colors py-2 px-6 rounded-full flex flex-row gap-3 items-center">
								<span className="grow">
									<Typography size={28} weight="medium" color="#1E6091">
										Inicio
									</Typography>
								</span>
								<Home className="shrink" color="#1E6091" />
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
