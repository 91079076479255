import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">AVISO DE PRIVACIDAD</h1>
      <p className="text-sm text-gray-600 mb-4">Fecha de última actualización: 14 de noviembre de 2024</p>
      
      <section className="prose prose-sm max-w-none">
        <h2 className="text-xl font-bold mt-6 mb-4">1. INTRODUCCIÓN</h2>
        <p>
          En Finclu (FINCLUYENTE S.A.P.I. DE C.V.), también conocido como "Finclu", respetamos
          su privacidad y estamos comprometidos con la protección de sus datos personales. Este
          aviso de privacidad explica cómo recopilamos, utilizamos, compartimos y protegemos su
          información personal, de acuerdo con la Ley Federal de Protección de Datos Personales en
          Posesión de los Particulares (en adelante, "la Ley"), su Reglamento, y las disposiciones
          aplicables de Google Play.
        </p>
        <p>
          Al utilizar nuestros servicios y aceptar este aviso, usted consiente expresamente el
          tratamiento de sus datos personales conforme a los términos aquí establecidos.
        </p>

        <h2 className="text-xl font-bold mt-6 mb-4">2. IDENTIDAD Y DOMICILIO DEL RESPONSABLE</h2>
        <p>
          <strong>Responsable:</strong> FINCLUYENTE S.A.P.I. DE C.V. ("Finclu")<br />
          <strong>Domicilio:</strong> PASEO DE LA REFORMA 26 17 25<br />
          BUCARELI DONATO GUER<br />
          JUAREZ VMC CUAUHTEMOC<br />
          C.P.06600<br />
          CUAUHTEMOC CIUDAD DE,CDMX<br />
          <strong>Sitio web:</strong> https://www.fincluyente.com.mx<br />
          <strong>Correo de contacto:</strong> soporte@fincluyente.com
        </p>

        <h2 className="text-xl font-bold mt-6 mb-4">3. DATOS PERSONALES QUE RECOPILAMOS Y SU FINALIDAD</h2>
        <div className="ml-4">
          <h3 className="font-semibold mb-2">Clientes Finales:</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Datos personales:</strong> nombre completo, correo electrónico, teléfono, CLABE
              interbancaria, datos de tarjeta de débito/crédito, y datos de geolocalización.
            </li>
            <li>
              <strong>Finalidades:</strong> Proveer servicios financieros, procesar pagos, validar identidad,
              prevención de fraude y análisis de comportamiento.
            </li>
          </ul>

          <h3 className="font-semibold mb-2">Comerciantes:</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Datos personales y corporativos:</strong> razón social, RFC, CURP, identificación oficial,
              información financiera y de negocio.
            </li>
            <li>
              <strong>Finalidades:</strong> Gestionar relaciones contractuales, procesar pagos, análisis de riesgo,
              marketing y cumplimiento legal.
            </li>
          </ul>
        </div>

        <h3 className="font-semibold mt-4 mb-2">Tecnologías de Rastreo:</h3>
        <p>
          Utilizamos cookies, web beacons y tecnologías similares para mejorar la experiencia del
          usuario y optimizar nuestros servicios. Los datos recopilados incluyen la dirección IP,
          identificadores de dispositivo y comportamiento en la aplicación.
        </p>

        <h2 className="text-xl font-bold mt-6 mb-4">4. TRANSFERENCIA Y USO COMPARTIDO DE DATOS PERSONALES</h2>
        <p>Finclu podrá compartir sus datos personales con terceros bajo los siguientes supuestos:</p>
        <ol className="list-decimal ml-6 mb-4">
          <li>Proveedores de servicios: Para validación de identidad, prevención de fraude y análisis de riesgos.</li>
          <li>Entidades financieras: Para el procesamiento de pagos y cumplimiento regulatorio.</li>
          <li>Marketing y análisis: Con empresas que nos asisten en campañas de promoción, siempre bajo acuerdos de confidencialidad.</li>
        </ol>

        <h2 className="text-xl font-bold mt-6 mb-4">5. MEDIDAS DE SEGURIDAD</h2>
        <p>
          Finclu implementa medidas técnicas, administrativas y físicas para proteger sus datos
          personales contra acceso no autorizado, pérdida, alteración o divulgación. No obstante, al
          usar plataformas digitales, existe el riesgo inherente de exposición de datos, del cual Finclu
          no puede garantizar protección absoluta.
        </p>

        <h2 className="text-xl font-bold mt-6 mb-4">6. DERECHOS ARCO</h2>
        <p>
          Usted o su representante legal pueden ejercer sus derechos ARCO en cualquier momento
          enviando una solicitud al correo: soporte@fincluyente.com.
        </p>
        
        <h3 className="font-semibold mt-4 mb-2">Requisitos para ejercer Derechos ARCO:</h3>
        <ul className="list-disc ml-6 mb-4">
          <li>Identificación oficial o, en su caso, poder notarial.</li>
          <li>Descripción de los datos personales y el derecho que desea ejercer.</li>
          <li>Dirección de correo electrónico para recibir notificaciones.</li>
        </ul>

        <h3 className="font-semibold mt-4 mb-2">Plazos de respuesta:</h3>
        <p>
          Una vez recibida su solicitud completa, Finclu responderá en un máximo de 20 días hábiles.
          Si procede, se hará efectiva en los siguientes 15 días hábiles.
        </p>

        <h2 className="text-xl font-bold mt-6 mb-4">7. CONSERVACIÓN Y ELIMINACIÓN DE DATOS</h2>
        <p>
          Los datos personales serán conservados únicamente durante el tiempo necesario para
          cumplir con las finalidades establecidas o conforme lo requieran las disposiciones legales
          aplicables. Una vez cumplido este periodo, sus datos serán eliminados de forma segura.
        </p>

        <h2 className="text-xl font-bold mt-6 mb-4">8. REVOCACIÓN DEL CONSENTIMIENTO</h2>
        <p>
          Usted puede revocar su consentimiento para el tratamiento de sus datos en cualquier
          momento, enviando una solicitud al correo: soporte@fincluyente.com. No obstante, dicha
          revocación puede limitar su acceso a algunos de nuestros servicios.
        </p>

        <h2 className="text-xl font-bold mt-6 mb-4">9. MODIFICACIONES AL AVISO DE PRIVACIDAD</h2>
        <p>
          Finclu se reserva el derecho de modificar este aviso de privacidad en cualquier momento.
          Cualquier cambio será notificado a través de nuestra página web y en la aplicación. El uso
          continuado de nuestros servicios después de dichos cambios implica su aceptación.
        </p>

        <h2 className="text-xl font-bold mt-6 mb-4">10. CONTACTO</h2>
        <p>
          Para cualquier pregunta, comentario o inquietud sobre este aviso de privacidad, por favor
          contáctenos en:
        </p>
        <p>
          <strong>Correo electrónico:</strong> soporte@fincluyente.com<br />
          <strong>Página web:</strong> https://www.fincluyente.com.mx
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;