import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@/UI/Components/Typography/Typography';
import { ChevronRight, Menu, X } from 'react-feather';
import DownloadAppButton from '@/UI/Components/DownloadAppButton/DownloadAppButton';

const Navbar = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<>
			<div className="flex flex-row justify-center items-center bg-[#518AC8] sticky">
				<div className="flex flex-row w-4/5 p-6 items-center">
					<div className="flex flex-row gap-8 grow">
						<div className="flex flex-col gap-2 cursor-pointer">
							<Link to="/">
								<img src="/logo.svg" alt="Fincluyente" />
							</Link>
						</div>
					</div>

					<div className="md:hidden">
						<button onClick={() => setIsMenuOpen(true)} className="p-2">
							<Menu className="h-6 w-6 text-white" />
						</button>
					</div>

					<div className="hidden md:flex flex-row gap-3">
						<a
							className="py-2 px-8 rounded-lg flex flex-row gap-2"
							target="_blank"
							href={`https://wa.me/+525576972475?text=${encodeURIComponent('Hola Finclu!')}`}
						>
							<Typography size={14} weight="medium" color="white">
								<u>Habla con Finclu</u>
							</Typography>
							<ChevronRight className="shrink" color="white" />
						</a>
						<DownloadAppButton />
					</div>
				</div>
			</div>

			<div
				className={`z-20 fixed inset-y-0 right-0 w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
					isMenuOpen ? 'translate-x-0' : 'translate-x-full'
				}`}
			>
				<div className="p-4">
					<div className="flex justify-end">
						<button onClick={() => setIsMenuOpen(false)} className="p-2">
							<X className="h-6 w-6" />
						</button>
					</div>

					<div className="flex flex-col mt-4">
						<div className="hover:bg-slate-50 p-3" onClick={() => {}}>
							<Typography>Descarga la App</Typography>
						</div>
						<div className="hover:bg-slate-50 p-3">
							<a
								target="_blank"
								href={`https://wa.me/+525576972475?text=${encodeURIComponent('Hola Finclu!')}`}
							>
								<Typography>Habla con Finclu</Typography>
							</a>
						</div>
					</div>
				</div>
			</div>

			{isMenuOpen && (
				<div
					className="fixed inset-0 bg-black bg-opacity-50 z-10"
					onClick={() => setIsMenuOpen(false)}
				/>
			)}
		</>
	);
};

export default Navbar;
