import React from 'react';
import MarkdownRenderer from '@/UI/Components/MarkdownRenderer/MarkdownRenderer';
import Credit from '@/lib/markdown/credit.md?raw';

const CreditOperation: React.FC = () => {
	return (
		<div className="flex justify-center items-center p-6">
			<MarkdownRenderer content={Credit} />
		</div>
	);
};

export default CreditOperation;
