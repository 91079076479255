import React from 'react';
import { WEIGHTS, TYPOGRAPHY_COLOR_TYPES } from '@/UI/globalTypes';
import { getTypographyStyles } from './styleUtils';

export interface TypographyProps {
	children: React.ReactNode;
	type?: 'text' | 'display';
	size?: number;
	weight?: WEIGHTS;
	color?: TYPOGRAPHY_COLOR_TYPES;
}

export const Typography: React.FC<TypographyProps> = ({
	children,
	size = 16,
	weight = 'regular',
	color = 'default'
}) => {
	const styles = getTypographyStyles(size, weight, color);
	return <span style={styles}>{children}</span>;
};
