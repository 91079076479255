import React from 'react';
import { Typography } from '@/UI/Components/Typography/Typography';
import { ChevronRight } from 'react-feather';
import DownloadAppButton from '@/UI/Components/DownloadAppButton/DownloadAppButton';

const InclusiveSection: React.FC = () => {
	return (
		<div className="flex flex-col gap-4 w-full p-6">
			<div className="flex flex-col gap-6 sm:flex-row sm:justify-center min-h-[calc(100vh-70px)] md:min-h-[calc(100vh-90px)] py-6">
				<div className="flex flex-col md:flex-row w-full md:w-4/5 items-center gap-6 justify-center">
					<div className="flex flex-col w-full  md:w-1/2 gap-8 px-6">
						<Typography size={17} weight="medium">
							Financiera Incluyente
						</Typography>
						<div className="flex flex-col gap-2">
							<Typography size={44} weight="bold">
								Acceso Financiero para Todos
							</Typography>
							<Typography size={28}>¡Empodera tu negocio con Fincluyente!</Typography>
						</div>
						<div className="flex flex-col gap-6">
							<Typography size={18}>
								Somos la plataforma financiera incluyente diseñada para eliminar barreras y brindar
								acceso a crédito y servicios financieros a microempresarios en México.
							</Typography>
							<Typography size={18}>
								Con Fincluyente, obtén las herramientas financieras que necesitas para alcanzar tus
								metas, con confianza y sin complicaciones.
							</Typography>
						</div>
						<div className="flex flex-col md:flex-row  gap-3">
							<DownloadAppButton />
							<a
								className="py-2 px-8 rounded-lg flex flex-row gap-2"
								target="_blank"
								href={`https://wa.me/+525576972475?text=${encodeURIComponent('Hola Finclu!')}`}
							>
								<Typography size={14} weight="semibold" color="black">
									<u>Habla con Finclu</u>
								</Typography>
								<ChevronRight className="shrink" color="black" />
							</a>
						</div>
					</div>
					<div className="flex flex-col gap-4">
						<img src="/inclusiveSectionImage.svg" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default InclusiveSection;
