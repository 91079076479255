import React from 'react';
import { Typography } from '@/UI/Components/Typography/Typography';
import { ChevronRight } from 'react-feather';
import Modal from '@/UI/Components/Modal/Modal';

const APP_STORE_URL = 'https://apps.apple.com/6475484002';
const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.finclu.app';

const DownloadAppButton: React.FC = () => {
	const [isModalOpen, setIsModalOpen] = React.useState(false);

	const detectMobileDevice = () => {
		const userAgent = navigator.userAgent.toLowerCase();
		const isIOS = /iphone|ipad|ipod/.test(userAgent);
		const isAndroid = /android/.test(userAgent);
		return { isIOS, isAndroid };
	};

	const handleClick = () => {
		const { isIOS, isAndroid } = detectMobileDevice();

		if (isIOS) {
			window.open(APP_STORE_URL, '_blank');
		} else if (isAndroid) {
			window.open(PLAY_STORE_URL, '_blank');
		} else {
			setIsModalOpen(true);
		}
	};

	const handleStoreSelect = (url: string) => {
		window.open(url, '_blank');
		setIsModalOpen(false);
	};

	return (
		<>
			<button
				className="bg-[#1E6091] hover:bg-[#6F99B9] transition-colors py-2 px-6 rounded-full flex flex-row gap-2"
				onClick={handleClick}
			>
				<span className="grow">
					<Typography size={14} weight="medium" color="white">
						Descarga la App
					</Typography>
				</span>
				<ChevronRight className="shrink" color="white" />
			</button>

			<Modal isOpen={isModalOpen} closeable onClose={() => setIsModalOpen(false)}>
				<div className="flex flex-col gap-4 p-6">
					<Typography size={18} weight="bold">
						Descarga la App
					</Typography>
					<Typography size={14}>Por favor selecciona tu tienda de aplicaciones.</Typography>
					<div className="flex flex-row gap-8 justify-center">
						<div
							className="flex w-1/3 cursor-pointer"
							onClick={() => handleStoreSelect(APP_STORE_URL)}
						>
							<img src="/appstore-logo.png" alt="disponible-en-appstore" />
						</div>
						<div
							className="flex w-1/3 cursor-pointer"
							onClick={() => handleStoreSelect(PLAY_STORE_URL)}
						>
							<img src="/google-play-logo.svg" alt="disponibleZZZ" />
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default DownloadAppButton;
