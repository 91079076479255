import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '@/UI/Components/Footer/Footer';
import Navbar from '@/UI/Components/Navbar/Navbar';

const App: React.FC = () => {
	return (
		<>
			<Navbar />
			<Outlet />
			<Footer />
		</>
	);
};
export default App;
