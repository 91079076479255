import { FONT_WEIGHTS, TYPOGRAPHY_COLORS } from '@/UI/globalTheme';
import { WEIGHTS, TYPOGRAPHY_COLOR_TYPES } from '@/UI/globalTypes';

export function getTypographyStyles(size: number, weight: WEIGHTS, color: TYPOGRAPHY_COLOR_TYPES) {
	return {
		fontWeight: FONT_WEIGHTS[weight],
		fontSize: `${size}px`,
		color: TYPOGRAPHY_COLORS[color] || color
	};
}
