import { COLOR_TYPE, SIZE, WEIGHTS, TYPOGRAPHY_COLOR_TYPES } from './globalTypes';

export const BRAND_COLORS = {
	brand25: '#F1F5F8',
	brand50: '#E3EBF2',
	brand100: '#C8D7E4',
	brand200: '#A3BDD2',
	brand300: '#8CADC7',
	brand400: '#6F99B9',
	brand500: '#4C80A7',
	brand600: '#1E6091',
	brand700: '#194D74',
	brand800: '#133E5D',
	brand900: '#10324A'
};

export const ERROR_COLORS = {
	error25: '#FFFBFA',
	error50: '#FEF3F2',
	error100: '#FEE4E2',
	error200: '#FECDCA',
	error300: '#FDA29B',
	error400: '#F97066',
	error500: '#F04438',
	error600: '#D92D20',
	error700: '#B42318',
	error800: '#912018',
	error900: '#7A271A'
};

export const GRAY_COLORS = {
	gray25: '#FCFCFD',
	gray50: '#F9FAFB',
	gray100: '#F2F4F7',
	gray200: '#E4E7EC',
	gray300: '#D0D5DD',
	gray400: '#98A2B3',
	gray500: '#667085',
	gray600: '#475467',
	gray700: '#344054',
	gray800: '#1D2939',
	gray900: '#101828'
};

export const WARNING_COLORS = {
	warning25: '#FFFCF5',
	warning50: '#FFFAEB',
	warning100: '#FEF0C7',
	warning200: '#FEDF89',
	warning300: '#FEC84B',
	warning400: '#FDB022',
	warning500: '#F79009',
	warning600: '#DC6803',
	warning700: '#B54708',
	warning800: '#93370D',
	warning900: '#7A2E0E'
};

export const SUCCESS_COLORS = {
	success25: '#F6FEF9',
	success50: '#ECFDF3',
	success100: '#D1FADF',
	success200: '#A6F4C5',
	success300: '#6CE9A6',
	success400: '#32D583',
	success500: '#12B76A',
	success600: '#039855',
	success700: '#027A48',
	success800: '#05603A',
	success900: '#054F31'
};

export const BLUE_GRAY_COLORS = {
	blueGray25: '#FCFCFD',
	blueGray50: '#F8F9FC',
	blueGray100: '#EAECF5',
	blueGray200: '#D5D9EB',
	blueGray300: '#AFB5D9',
	blueGray400: '#717BBC',
	blueGray500: '#4E5BA6',
	blueGray600: '#3E4784',
	blueGray700: '#363F72',
	blueGray800: '#293056',
	blueGray900: '#101323'
};

export const PURPLE_COLORS = {
	purple25: '#FCFAFF',
	purple50: '#F9F5FF',
	purple100: '#F4EBFF',
	purple200: '#E9D7FE',
	purple300: '#D6BBFB',
	purple400: '#B692F6',
	purple500: '#9E77ED',
	purple600: '#7f56d9',
	purple700: '#6941C6',
	purple800: '#53389E',
	purple900: '#42307D'
};

export const SHADOWS: Record<SIZE, string> = {
	xs: '0px 1px 2px 0px #1018280D',
	sm: '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A',
	md: '0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A',
	lg: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
	xl: '0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814',
	xxl: '0px 24px 48px -12px #1018282E'
};

export const FONT_WEIGHTS: Record<WEIGHTS, number> = {
	regular: 400,
	medium: 500,
	semibold: 600,
	bold: 700
};

export const FONT_SIZES: Record<SIZE, string> = {
	xs: '12px',
	sm: '14px',
	md: '16px',
	lg: '18px',
	xl: '20px',
	xxl: '22px'
};

export const BG_COLORS: Record<COLOR_TYPE, string> = {
	primary: BRAND_COLORS.brand50,
	warning: WARNING_COLORS.warning50,
	success: SUCCESS_COLORS.success50,
	blueGray: BLUE_GRAY_COLORS.blueGray50,
	gray: GRAY_COLORS.gray100,
	error: ERROR_COLORS.error50,
	white: '#FFFFFF'
};

export const TEXT_COLORS: Record<COLOR_TYPE, string> = {
	primary: BRAND_COLORS.brand700,
	warning: WARNING_COLORS.warning700,
	success: SUCCESS_COLORS.success700,
	blueGray: BLUE_GRAY_COLORS.blueGray700,
	gray: GRAY_COLORS.gray700,
	error: ERROR_COLORS.error700,
	white: BRAND_COLORS.brand700
};

export const TYPOGRAPHY_COLORS: Record<TYPOGRAPHY_COLOR_TYPES, string> = {
	default: GRAY_COLORS.gray900,
	subtitle: GRAY_COLORS.gray600,
	brand: BRAND_COLORS.brand600,
	error: ERROR_COLORS.error500
};

export const FEATURED_ICON_COLORS: Record<COLOR_TYPE, string> = {
	primary: BRAND_COLORS.brand100,
	warning: WARNING_COLORS.warning100,
	success: SUCCESS_COLORS.success100,
	blueGray: BLUE_GRAY_COLORS.blueGray100,
	gray: GRAY_COLORS.gray200,
	error: ERROR_COLORS.error100,
	white: '#FFFFFF'
};
