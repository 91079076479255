import React from 'react';
import { Typography } from '@/UI/Components/Typography/Typography';
import DownloadAppButton from '@/UI/Components/DownloadAppButton/DownloadAppButton';
import { ChevronRight } from 'react-feather';

const CreditSection: React.FC = () => {
	return (
		<div
			className="flex flex-col gap-4 w-full p-6 bg-cover bg-[#518AC8] md:bg-white"
			style={{ backgroundImage: "url('/CreditSectionBackground.svg')" }}
		>
			<div className="flex flex-col gap-6 sm:flex-row sm:justify-center sm:items-end xl:items-center min-h-[calc(100vh-70px)] md:min-h-[calc(100vh-90px)] py-6">
				<div className="flex flex-col md:flex-row md:w-4/5 gap-8 mt-20 justify-center">
					<div className="flex flex-col md:w-1/2 gap-8 px-6">
						<Typography size={17} weight="medium" color="white">
							FincluCredito
						</Typography>
						<div className="flex flex-col gap-2">
							<Typography size={44} weight="bold" color="white">
								Acceso a Crédito sin Complicaciones
							</Typography>
						</div>
						<div className="flex flex-col gap-1">
							<Typography size={18} color="white">
								Obtén financiamiento de manera inclusiva y accesible con Fincluyente. Ofrecemos
								créditos flexibles y personalizados para apoyar a microempresarios y personas sin
								acceso a servicios financieros formales, ayudándote a cumplir tus sueños y
								objetivos.
							</Typography>
							<Typography size={18} color="white">
								Sin barreras, sin complicaciones.
							</Typography>
						</div>
						<div className="flex flex-col md:flex-row gap-3">
							<DownloadAppButton />
							<a
								className="py-2 px-8 rounded-lg flex flex-row gap-2"
								target="_blank"
								href={`https://wa.me/+525576972475?text=${encodeURIComponent('Hola Finclu!')}`}
							>
								<Typography size={14} weight="semibold" color="white">
									<u>Habla con Finclu</u>
								</Typography>
								<ChevronRight className="shrink" color="white" />
							</a>
						</div>
					</div>
					<div className="flex flex-col gap-4">
						<img src="/CreditSectionImage.svg" alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreditSection;
